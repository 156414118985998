/**
 *  Contains custom styles for the React Flow Library
 */

.react-flow__minimap {
    bottom: unset;
    right: unset;
    top: 10px;
    left: 10px;
}

.react-flow__handle.react-flow__handle-left::after {
    content: attr(data-label);
    font-size: 6px;
    color: currentColor;
    margin-left: 8px;
    position: absolute;
    top: 0;
    left: 0;
    font-family: monospace;
}

.react-flow__handle.react-flow__handle-right::before {
    content: attr(data-label);
    font-size: 6px;
    color: currentColor;
    margin-right: 8px;
    position: absolute;
    top: 0;
    right: 0;
    font-family: monospace;
}

.react-flow__node .inputContainer {
    margin-left: 20px;
    margin-right: 20px;
    pointer-events: none;
}

.react-flow__node .inputContainer > input {
    width: 100%;
    pointer-events: auto;
}

.react-flow__node .inputContainer > button {
    pointer-events: auto;
}